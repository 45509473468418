<template>
  <el-dialog :title="form.check ? '查看' : form.id ? '编辑' : '添加'"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    :close-on-click-modal="false"
    width="640px"
    @close="dialogClose"
    :destroy-on-close="true"
    :before-close="handleClose">
    <div class="content">
      <el-form ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
        label-width="100px">
        <el-form-item label="保险公司"
          prop="companyName">
          <el-select v-model="form.companyName"
            placeholder="请选择保险公司"
            :disabled="form.check?true:false"
            @change="companySelect"
            value-key="dictKey">
            <el-option v-for="(item,index) in dict.insuranceCompany"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item"></el-option>
          </el-select>
        </el-form-item>
        <div class="type-name">{{formTypeName}}</div>
        <template v-if="formType === 0">
          <el-form-item label="主险名称"
            prop="insuranceName">
            <el-input v-model="form.insuranceName"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="保额/限额">
            <div style="display:flex;align-items: center;">
              <el-input v-model="form.insPolicyInsuranceTypeAppendEntityList[0].insuranceAmount"
                v-number-input.float="2"
                :disabled="form.check?true:false"></el-input>
              <el-dropdown @command="handleCommand($event,'')"
                trigger="click"
                :disabled="form.check?true:false">
                <span class="el-dropdown-link">
                  {{form.insPolicyInsuranceTypeAppendEntityList[0].unit === 1 ? '%':'元'}}
                  <i class="el-icon-arrow-down"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:1,key:'unit'}">%</el-dropdown-item>
                  <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:0,key:'unit'}">元</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </el-form-item>
          <el-form-item label="每次赔偿额">
            <el-input v-model="form.insPolicyInsuranceTypeAppendEntityList[0].compensationAmount"
              v-number-input.float="2"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="区分产地"
            :prop="'insPolicyInsuranceTypeAppendEntityList.' + 0 + '.differentiateOrigin'"
            :rules="{
                required: true, message: '请选择是否区分产地', trigger: 'change'
            }">
            <el-radio-group v-model="form.insPolicyInsuranceTypeAppendEntityList[0].differentiateOrigin"
              :disabled="form.check?true:false">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
          <template v-if="form.insPolicyInsuranceTypeAppendEntityList[0].differentiateOrigin === 1">
            <div style="display:flex">
              <el-form-item label="进口费率"
                :prop="'insPolicyInsuranceTypeAppendEntityList.' + 0 + '.importRate'"
                :rules="{
                required: true, message: '进口费率不能为空', trigger: 'blur'
            }">
                <div style="display:flex;align-items: center;">
                  <el-input v-model="form.insPolicyInsuranceTypeAppendEntityList[0].importRate"
                    v-number-input.float="3"
                    :disabled="form.check?true:false"></el-input>
                  <el-dropdown @command="handleCommand($event,'rateUnit')"
                    trigger="click"
                    :disabled="form.check?true:false">
                    <span class="el-dropdown-link">
                      {{form.insPolicyInsuranceTypeAppendEntityList[0].importUnit === 1 ? '%':'元'}}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:1,key:'importUnit'}">%</el-dropdown-item>
                      <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:0,key:'importUnit'}">元</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-form-item>
              <el-form-item label="国产费率"
                label-width="80px"
                style="margin-left:20px"
                :prop="'insPolicyInsuranceTypeAppendEntityList.' + 0 + '.rate'"
                :rules="{
                required: true, message: '国产费率不能为空', trigger: 'blur'
            }">
                <div style="display:flex;align-items: center;">
                  <el-input v-model="form.insPolicyInsuranceTypeAppendEntityList[0].rate"
                    v-number-input.float="3"
                    :disabled="form.check?true:false"></el-input>
                  <el-dropdown @command="handleCommand($event,'importUnit')"
                    trigger="click"
                    :disabled="form.check?true:false">
                    <span class="el-dropdown-link">
                      {{form.insPolicyInsuranceTypeAppendEntityList[0].rateUnit === 1 ? '%':'元'}}
                      <i class="el-icon-arrow-down"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:1,key:'rateUnit'}">%</el-dropdown-item>
                      <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:0,key:'rateUnit'}">元</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </el-form-item>
            </div>
          </template>
          <template v-else>
            <el-form-item label="费率"
              :prop="'insPolicyInsuranceTypeAppendEntityList.' + 0 + '.rate'"
              :rules="{
                required: true, message: '费率不能为空', trigger: 'blur'
            }">
              <div style="display:flex;align-items: center;">
                <el-input v-model="form.insPolicyInsuranceTypeAppendEntityList[0].rate"
                  v-number-input.float="6"
                  :disabled="form.check?true:false"></el-input>
                <el-dropdown @command="handleCommand($event,'importUnit')"
                  trigger="click"
                  :disabled="form.check?true:false">
                  <span class="el-dropdown-link">
                    {{form.insPolicyInsuranceTypeAppendEntityList[0].rateUnit === 1 ? '%':'元'}}
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:1,key:'rateUnit'}">%</el-dropdown-item>
                    <el-dropdown-item :command="{data:form.insPolicyInsuranceTypeAppendEntityList[0],value:0,key:'rateUnit'}">元</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </template>
          <el-form-item label="说明">
            <el-input type="textarea"
              rows="4"
              maxlength="3000"
              show-word-limit
              v-model="form.illustrate"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
        </template>
        <template v-else-if="formType === 1">
          <el-form-item label="名称"
            prop="insuranceName">
            <el-input v-model="form.insuranceName"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="类型"
            prop="termConfigurationType">
            <el-select v-model="form.termConfigurationType"
              placeholder="请选择类型"
              @change="handleSelectType"
              :disabled="form.check?true:false">
              <el-option v-for="(item) in optionArr"
                :key="item.value"
                :label="item.text"
                :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <div v-for="(item,index) in form.insPolicyInsuranceTypeAppendEntityList"
            :key="index"
            class="arr-wrap">
            <el-form-item label="选项名称"
              :prop="'insPolicyInsuranceTypeAppendEntityList.' + index + '.optionName'"
              :rules="{
                required: true, message: '选项名称不能为空', trigger: 'blur'
            }">
              <div style="display:flex;align-item:center">
                <el-input v-model="item.optionName"
                  :disabled="form.check?true:false"></el-input><el-button v-if="index > 0"
                  style="margin-left:10px;color:red"
                  type="text"
                  @click="form.insPolicyInsuranceTypeAppendEntityList.splice(index,1)">删除</el-button>
              </div>
            </el-form-item>
            <el-form-item label="保额/限额">
              <div style="display:flex;align-items: center;">
                <el-input v-model="item.insuranceAmount"
                  v-number-input.float="2"
                  :disabled="form.check?true:false"></el-input>
                <el-dropdown @command="handleCommand($event,'')"
                  trigger="click"
                  :disabled="form.check?true:false">
                  <span class="el-dropdown-link">
                    {{item.unit === 1 ? '%':'元'}}
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{data:item,value:1,key:'unit'}">%</el-dropdown-item>
                    <el-dropdown-item :command="{data:item,value:0,key:'unit'}">元</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
            <el-form-item label="每次赔偿额">
              <el-input v-model="item.compensationAmount"
                v-number-input.float="2"
                :disabled="form.check?true:false"></el-input>
            </el-form-item>
            <el-form-item label="费率"
              style="margin-bottom:0">
              <div style="display:flex;align-items: center;">
                <el-input v-model="item.rate"
                  v-number-input.float="6"
                  :disabled="form.check?true:false"></el-input>
                <el-dropdown @command="handleCommand($event,'')"
                  trigger="click"
                  :disabled="form.check?true:false">
                  <span class="el-dropdown-link">
                    {{item.rateUnit === 1 ? '%':'元'}}
                    <i class="el-icon-arrow-down"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{data:item,value:1,key:'rateUnit'}">%</el-dropdown-item>
                    <el-dropdown-item :command="{data:item,value:0,key:'rateUnit'}">元</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-form-item>
          </div>
          <div class="button"
            @click="form.insPolicyInsuranceTypeAppendEntityList.push({ unit: 1, importUnit: 1, rateUnit: 1,differentiateOrigin:0 })"
            v-if="form.termConfigurationType !== 0"><i class="el-icon-circle-plus-outline"
              style="margin-right:5px"></i>追加</div>
          <el-form-item label="说明"
            style="margin-top:10px">
            <el-input type="textarea"
              rows="4"
              maxlength="3000"
              show-word-limit
              v-model="form.illustrate"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
        </template>
        <template v-else-if="formType === 2 || formType === 3 || formType === 4">
          <el-form-item label="名称"
            label-width="80px"
            prop="termConfigurationName">
            <el-input v-model="form.termConfigurationName"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-input type="textarea"
            rows="4"
            maxlength="3000"
            show-word-limit
            v-model="form.illustrate"
            style="margin:0 0 20px 0"
            :disabled="form.check?true:false"></el-input>
        </template>
        <template v-else>
          <el-form-item label="名称"
            label-width="80px"
            prop="termConfigurationName">
            <el-input v-model="form.termConfigurationName"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="扩展不计免赔1（0免赔）"
            class="alignTop">
            <el-input type="textarea"
              rows="4"
              maxlength="3000"
              show-word-limit
              v-model="form.extensionWithoutDeductibleOne"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="扩展不计免赔2（绝对免赔1000元）"
            class="alignTop">
            <el-input type="textarea"
              rows="4"
              maxlength="3000"
              show-word-limit
              v-model="form.extensionWithoutDeductibleTwo"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
          <el-form-item label="未扩展不计免赔"
            class="alignTop">
            <el-input type="textarea"
              rows="4"
              maxlength="3000"
              show-word-limit
              v-model="form.extensionWithoutDeductible"
              :disabled="form.check?true:false"></el-input>
          </el-form-item>
        </template>
        <el-form-item label="条款文件"
          prop="termsDocumentsId">
          <el-select v-model="form.termsDocumentsId"
            placeholder="请选择条款文件"
            :disabled="form.check?true:false">
            <el-option v-for="(item) in documentsArr"
              :key="item.id"
              :label="item.termsDocumentsName"
              :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer"
      class="dialog-footer">
      <el-button @click="resetForm"
        v-if="!form.check">重 置</el-button>
      <el-button type="primary"
        @click="submit"
        v-if="!form.check">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { dictionaryBatch, termsDocumentsByCompany, addOrUpdateConfiguration } from "@/api/policy";
export default {
  model: {
    prop: 'showFormDialog',
    event: 'change'
  },
  props: {
    showFormDialog: Boolean,
    formType: Number,
    category: Number,
    info: Object
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showFormDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
        }
      }
    },
    info: {
      handler(v) {
        if (v) {
          this.form = JSON.parse(JSON.stringify(v));
          this.getDocuments(this.form.companyValue);
          this.$forceUpdate();
        }
      }
    },
  },
  data() {
    return {
      form: { insPolicyInsuranceTypeAppendEntityList: [{ unit: 1, importUnit: 1, rateUnit: 1, differentiateOrigin: 0 }] },
      dialogVisible: false,
      dict: {},
      documentsArr: [],
      optionArr: [{ value: 0, text: '单项' }, { value: 1, text: '选项' }, { value: 2, text: '下拉选项' }],
      rules: {
        companyName: [
          { required: true, message: '请选择保险公司', trigger: 'change' }
        ],
        insuranceName: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        termConfigurationName: [
          { required: true, message: '请填写名称', trigger: 'blur' }
        ],
        termConfigurationType: [
          { required: true, message: '请选择类型', trigger: 'change' }
        ],
      },
      formTypeArr: [{ value: 0, text: '主险' }, { value: 1, text: '附加险' }, { value: 2, text: '特别约定' }, { value: 3, text: '免费拓展条款' }, { value: 4, text: '特别说明' }, { value: 5, text: '每次事故绝对免赔额' }]
    };
  },
  created() {
    this.init();
  },
  computed: {
    formTypeName() {
      if (this.formType === 0) return '主险';
      if (this.formType === 1) return '附加险';
      if (this.formType === 2) return '特别约定';
      if (this.formType === 3) return '免费拓展条款';
      if (this.formType === 4) return '特别说明';
      if (this.formType === 5) return '每次事故绝对免赔额';
    }
  },
  methods: {
    init() {
      dictionaryBatch({ codes: 'insuranceCompany' }).then(res => {
        this.dict = res.data;
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handleSelectType(e) {
      console.log(e);
      if (e === 0) {
        this.form.insPolicyInsuranceTypeAppendEntityList = this.form.insPolicyInsuranceTypeAppendEntityList.slice(0, 1);
      }
    },
    handleRadioSelect(item) {
      console.log(item, 22, this.form);
    },
    submit() {
      this.form.dataType = this.formType;
      this.form.classificationValue = this.formType;
      this.form.classificationName = this.formTypeArr.find(el => {
        return el.value === this.formType;
      }).text;
      this.form.category = this.category;
      if (this.formType === 0) this.form.termConfigurationType = 0;
      if (this.formType === 0 || this.formType === 1) this.form.termConfigurationName = this.form.insuranceName;
      this.$refs.form.validate((valid) => {
        if (valid) {
          addOrUpdateConfiguration(this.form).then(res => {
            this.dialogVisible = false;
            this.resetForm();
            this.$message({
              message: '操作成功',
              type: 'success'
            });
          });
        }
      });
    },
    resetForm() {
      this.form = { insPolicyInsuranceTypeAppendEntityList: [{ unit: 1, importUnit: 1, rateUnit: 1, differentiateOrigin: 0 }] };
    },
    dialogClose() {
      this.resetForm();
    },
    handleCommand(command, extraKey = '') {
      if (extraKey !== '') {
        command.data[extraKey] = command.value;
      }
      command.data[command.key] = command.value;
    },
    companySelect(item) {
      this.form.companyName = item.dictValue;
      this.form.companyValue = item.dictKey;
      this.form.companyCode = 'insuranceCompany';
      if (this.form.termsDocumentsId) this.form.termsDocumentsId = '';
      this.getDocuments(item.dictKey);
    },
    getDocuments(companyValue) {
      termsDocumentsByCompany({ companyValue, category: this.category }).then(res => {
        this.documentsArr = res.data;
      });
    }
  },
}
</script>
<style lang="scss" scoped>
@import 'src/style/Standard.scss';
.content {
  .type-name {
    font-size: 16px;
    color: #4278c9;
    margin: 15px 0;
    font-weight: bold;
  }
  .arr-wrap {
    background-color: #f1f6ff;
    padding: 15px 10px;
    border-radius: 10px;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }
  .button {
    color: #00bc0d;
    font-size: 14px;
    text-align: right;
    margin: 10px 0;
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409eff;
    display: flex;
    align-items: center;
    margin-left: 5px;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
}
::v-deep .el-dialog__body {
  max-height: 570px;
  overflow: auto;
}
::v-deep .alignTop {
  .el-form-item__label {
    float: none;
  }
  .el-form-item__content {
    margin-left: 0 !important;
  }
}
</style>
